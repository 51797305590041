var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticStyle: {
            border: "1px solid #dfdfdf !important",
            margin: "0",
            cursor: "pointer",
          },
          on: {
            click: function ($event) {
              return _vm.handleRowClick(_vm.documentPackage, _vm.hasChildren)
            },
          },
        },
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c(
              "span",
              {
                staticStyle: { display: "inline-block" },
                style: { marginLeft: _vm.nodeMargin + "px" },
                on: {
                  clicked: function ($event) {
                    return _vm.toggleChildren(_vm.documentPackage)
                  },
                },
              },
              [
                _vm.hasChildren && !_vm.documentPackage.is_selected
                  ? _c("SvgPlus")
                  : _vm._e(),
                _vm.hasChildren && _vm.documentPackage.is_selected
                  ? _c("SvgMinus")
                  : _vm._e(),
              ],
              1
            ),
            _vm._v("\n\t\t\t" + _vm._s(_vm.documentPackage.name) + "\n\t\t"),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.documentPackage.version &&
                    "V" + _vm.documentPackage.version
                ) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.getFileType(_vm.documentPackage)) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.documentPackage.fileName) + "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.documentPackage.publisher.fullName) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.displayDate(_vm.documentPackage.publishedOn)) +
                "\n\t\t"
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "1" } },
            [
              _vm.documentPackage.type === 11
                ? _c(
                    "b-dropdown",
                    {
                      staticStyle: { "border-right": "none !important" },
                      attrs: {
                        "no-caret": "",
                        dropleft: "",
                        boundary: "window",
                        variant: "none",
                        size: "sm",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("more-vertical", { attrs: { size: 16 } }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4229715230
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleClickView(_vm.documentPackage)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-color-rhapsody-in-blue" },
                            [
                              _c("Eye", { attrs: { size: 16 } }),
                              _vm._v(
                                " " + _vm._s(_vm.FormMSG(159, "View")) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleRowClick(_vm.documentPackage)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-color-rhapsody-in-blue" },
                            [
                              _c("Edit2", { attrs: { size: 16 } }),
                              _vm._v(
                                " " + _vm._s(_vm.FormMSG(160, "Edit")) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasChildren
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.documentPackage.is_selected,
                  expression: "documentPackage.is_selected",
                },
              ],
            },
            _vm._l(_vm.documentPackage.childDocList, function (docPackage) {
              return _c("tree-node-doc-package", {
                key: docPackage.id,
                attrs: {
                  "document-package": docPackage,
                  spacing: _vm.spacing + 30,
                },
                on: {
                  "tree-node-doc-package:view":
                    _vm.handleTreeNodeDocPackageView,
                  "tree-node-doc-package:click-row":
                    _vm.handleTreeNodeDocPackageClickRow,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }