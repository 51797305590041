var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            class:
              _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "12" } }, [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(130, "Documents")))]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "pt-0 pr-0 pl-0",
                            attrs: { "no-body": "" },
                          },
                          [
                            _c("b-card-text", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-tabs",
                                    {
                                      attrs: {
                                        "content-class": "custom-content-tabs",
                                        "nav-class": "custom-nav-tabs",
                                        "nav-wrapper-class":
                                          "custom-nav-wrapper",
                                      },
                                      on: {
                                        "activate-tab": (n, p, e) =>
                                          _vm.onTabClicked(n),
                                      },
                                      model: {
                                        value: _vm.tabs.activeTabs,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tabs, "activeTabs", $$v)
                                        },
                                        expression: "tabs.activeTabs",
                                      },
                                    },
                                    [
                                      _vm.canSeeDocument && _vm.useDoc
                                        ? _c(
                                            "b-tab",
                                            {
                                              attrs: {
                                                title: _vm.FormMSG(
                                                  189,
                                                  "Documents"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            sm: "12",
                                                            md: "6",
                                                            lg: "6",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _vm.$screen.width >=
                                                          992
                                                            ? _c(
                                                                "b-input-group",
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            31,
                                                                            "Type to Search"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.filter,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.filter =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "filter",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "b-input-group-append",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-input-group-text",
                                                                        {
                                                                          staticClass:
                                                                            "btn-search",
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .filter
                                                                            .length ===
                                                                          0
                                                                            ? _c(
                                                                                "Search",
                                                                                {
                                                                                  staticClass:
                                                                                    "icon",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#FFFFFF",
                                                                                      size: 16,
                                                                                      "stroke-width": 2.5,
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "X",
                                                                                {
                                                                                  staticClass:
                                                                                    "icon",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#FFFFFF",
                                                                                      size: 16,
                                                                                      "stroke-width": 2.5,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.filter =
                                                                                          ""
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "pt-1",
                                                          attrs: {
                                                            sm: "12",
                                                            md: "3",
                                                            lg: "3",
                                                            xl: "3",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                                value: true,
                                                                "unchecked-value": false,
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.handleChangeShowArchivedDocument,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      122,
                                                                      "Show archived package"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            sm: "12",
                                                            md: "3",
                                                            lg: "3",
                                                            xl: "3",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "w-200-px d-flex justify-content-center align-items-center pull-right",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.NewDocument()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "Plus"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    size: 18,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "px-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        17,
                                                                        "New document"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "mt-3" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-overlay",
                                                            {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                show: _vm.isBusyDataTable,
                                                                rounded: "lg",
                                                              },
                                                              on: {
                                                                "update:show":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.isBusyDataTable =
                                                                      $event
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.$screen
                                                                .width < 992
                                                                ? _c(
                                                                    "CardListBuilder",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          _vm.Docs,
                                                                        fields:
                                                                          _vm.docfields,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "actions",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      staticClass:
                                                                                        "btn bg-transparent border-0",
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "primary",
                                                                                          size: "sm",
                                                                                          title:
                                                                                            "Update document",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.rowClicked(
                                                                                              data.item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        _vm.getLucideIcon(
                                                                                          _vm
                                                                                            .ICONS
                                                                                            .EDIT
                                                                                            .name
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                _vm
                                                                                                  .ICONS
                                                                                                  .EDIT
                                                                                                  .color,
                                                                                              size: 20,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  data
                                                                                    .item
                                                                                    .fileName
                                                                                    .length >
                                                                                  0
                                                                                    ? _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              variant:
                                                                                                "primary",
                                                                                              size: "sm",
                                                                                              title:
                                                                                                "View document",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.viewDocument(
                                                                                                  data.item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .EYE
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    "#225CBD",
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm.showBtnTrashByStatus(
                                                                                    data
                                                                                  )
                                                                                    ? _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              variant:
                                                                                                "danger",
                                                                                              size: "sm",
                                                                                              title:
                                                                                                "Delete document",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.delItem(
                                                                                                  data
                                                                                                    .item
                                                                                                    .id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .TRASH
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .TRASH
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          2048595641
                                                                        ),
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm.$screen
                                                                .width >= 992
                                                                ? _c(
                                                                    "b-table",
                                                                    {
                                                                      ref: "myTable",
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "left",
                                                                        },
                                                                      attrs: {
                                                                        hover:
                                                                          _vm.hover,
                                                                        responsive:
                                                                          "sm",
                                                                        "sticky-header":
                                                                          "900px",
                                                                        selectable:
                                                                          "",
                                                                        selectedVariant:
                                                                          _vm.selectedColor,
                                                                        "select-mode":
                                                                          _vm.selectMode,
                                                                        items:
                                                                          _vm.Docs,
                                                                        fields:
                                                                          _vm.docfields,
                                                                        "current-page":
                                                                          _vm.currentPage,
                                                                        filter:
                                                                          _vm.filter,
                                                                        "per-page":
                                                                          _vm.perPage,
                                                                        bordered:
                                                                          "",
                                                                        small:
                                                                          "",
                                                                        "show-empty":
                                                                          "",
                                                                        "empty-text":
                                                                          _vm.FormMSG(
                                                                            264,
                                                                            "No document found"
                                                                          ),
                                                                        "head-variant":
                                                                          _vm.hv,
                                                                      },
                                                                      on: {
                                                                        "row-clicked":
                                                                          _vm.rowClicked,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "cell(view)",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  data
                                                                                    .item
                                                                                    .fileName
                                                                                    .length >
                                                                                    0 ||
                                                                                  data
                                                                                    .item
                                                                                    .childDocList
                                                                                    .length >
                                                                                    0
                                                                                    ? _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              variant:
                                                                                                "primary",
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.viewDocument(
                                                                                                  data.item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .EYE
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .EYE
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "cell(rem)",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _vm.showBtnTrashByStatus(
                                                                                    data
                                                                                  )
                                                                                    ? _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn bg-transparent border-0",
                                                                                          attrs:
                                                                                            {
                                                                                              variant:
                                                                                                "danger",
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.delItem(
                                                                                                  data
                                                                                                    .item
                                                                                                    .id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            _vm.getLucideIcon(
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .TRASH
                                                                                                .name
                                                                                            ),
                                                                                            {
                                                                                              tag: "component",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .ICONS
                                                                                                      .TRASH
                                                                                                      .color,
                                                                                                  size: 20,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          2950585032
                                                                        ),
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("loading", {
                                                    attrs: {
                                                      active: _vm.isLoading,
                                                      "is-full-page": true,
                                                      color: "#00A09C",
                                                    },
                                                    on: {
                                                      "update:active":
                                                        function ($event) {
                                                          _vm.isLoading = $event
                                                        },
                                                    },
                                                  }),
                                                  _c(
                                                    "b-modal",
                                                    {
                                                      staticClass:
                                                        "modal-success",
                                                      attrs: {
                                                        title: _vm.FormMSG(
                                                          27,
                                                          "Success!"
                                                        ),
                                                        "ok-variant": "success",
                                                        "ok-only": "",
                                                      },
                                                      on: {
                                                        ok: function ($event) {
                                                          _vm.successModal = false
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.successModal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.successModal = $$v
                                                        },
                                                        expression:
                                                          "successModal",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            this.FormMSG(
                                                              28,
                                                              "The new document has been saved."
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-modal",
                                                    {
                                                      staticClass:
                                                        "modal-warning",
                                                      attrs: {
                                                        "header-class":
                                                          "header-class-modal-doc-package",
                                                        "header-bg-variant":
                                                          "warning",
                                                        title: "Warning",
                                                        "ok-variant": "warning",
                                                        "ok-only": "",
                                                      },
                                                      on: {
                                                        ok: function ($event) {
                                                          _vm.documentWarningModal = false
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.documentWarningModal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.documentWarningModal =
                                                            $$v
                                                        },
                                                        expression:
                                                          "documentWarningModal",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.documentWarningMessage
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-modal",
                                                    {
                                                      staticClass:
                                                        "modal-success",
                                                      attrs: {
                                                        "header-class":
                                                          "header-class-modal-doc-package",
                                                        title: _vm.FormMSG(
                                                          27,
                                                          "Success!"
                                                        ),
                                                        "ok-variant": "success",
                                                        "ok-only": "",
                                                      },
                                                      on: {
                                                        ok: function ($event) {
                                                          _vm.successModalDelete = false
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.successModalDelete,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.successModalDelete =
                                                            $$v
                                                        },
                                                        expression:
                                                          "successModalDelete",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              29,
                                                              "Your document has been deleted."
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.canSeeDocument && _vm.useDoc
                                        ? _c(
                                            "b-tab",
                                            {
                                              attrs: {
                                                lazy: "",
                                                title: _vm.FormMSG(
                                                  190,
                                                  "Document package"
                                                ),
                                                active: _vm.activeTabDocPackage,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "table-hierarchical-doc-package"
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.canSeeCallSheet
                                        ? _c(
                                            "b-tab",
                                            {
                                              attrs: {
                                                lazy: "",
                                                title: _vm.FormMSG(
                                                  191,
                                                  "Call Sheets"
                                                ),
                                              },
                                            },
                                            [_c("Stageday")],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }