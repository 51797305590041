<template>
	<div>
		<b-row style="border: 1px solid #dfdfdf !important; margin: 0; cursor: pointer" @click="handleRowClick(documentPackage, hasChildren)">
			<b-col cols="4">
				<span style="display: inline-block" :style="{ marginLeft: nodeMargin + 'px' }" @clicked="toggleChildren(documentPackage)">
					<SvgPlus v-if="hasChildren && !documentPackage.is_selected" />
					<SvgMinus v-if="hasChildren && documentPackage.is_selected" />
				</span>
				{{ documentPackage.name }}
			</b-col>
			<b-col cols="1">
				{{ documentPackage.version && 'V' + documentPackage.version }}
			</b-col>
			<b-col cols="1">
				{{ getFileType(documentPackage) }}
			</b-col>
			<b-col cols="3">
				{{ documentPackage.fileName }}
			</b-col>
			<b-col cols="1">
				{{ documentPackage.publisher.fullName }}
			</b-col>
			<b-col cols="1">
				{{ displayDate(documentPackage.publishedOn) }}
			</b-col>
			<b-col cols="1" class="text-center">
				<b-dropdown
					v-if="documentPackage.type === 11"
					no-caret
					dropleft
					boundary="window"
					variant="none"
					size="sm"
					style="border-right: none !important"
				>
					<template #button-content>
						<more-vertical :size="16" />
					</template>
					<b-dropdown-item @click="handleClickView(documentPackage)">
						<span class="text-color-rhapsody-in-blue"> <Eye :size="16" /> {{ FormMSG(159, 'View') }} </span>
					</b-dropdown-item>
					<b-dropdown-item @click="handleRowClick(documentPackage)">
						<span class="text-color-rhapsody-in-blue"> <Edit2 :size="16" /> {{ FormMSG(160, 'Edit') }} </span>
					</b-dropdown-item>
				</b-dropdown>
			</b-col>
		</b-row>
		<div v-if="hasChildren" v-show="documentPackage.is_selected">
			<tree-node-doc-package
				v-for="docPackage in documentPackage.childDocList"
				:key="docPackage.id"
				:document-package="docPackage"
				:spacing="spacing + 30"
				@tree-node-doc-package:view="handleTreeNodeDocPackageView"
				@tree-node-doc-package:click-row="handleTreeNodeDocPackageClickRow"
			/>
		</div>
	</div>
</template>

<script>
import { Eye, MoreVertical, Edit2 } from 'lucide-vue';
import _ from 'lodash';
import SvgPlus from '@/components/Offers/SvgPlus';
import SvgMinus from '@/components/Offers/SvgMinus';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';

export default {
	name: 'TreeNodeDocPackage',

	mixins: [languageMessages],

	components: {
		Eye,
		SvgPlus,
		SvgMinus,
		MoreVertical,
		Edit2
	},

	props: {
		documentPackage: { type: Object, required: true },
		spacing: { type: Number, default: 0 }
	},

	data() {
		return {
			showChildren: false
		};
	},

	computed: {
		nodeMargin() {
			return this.spacing;
		},
		hasChildren() {
			if (this.documentPackage.childDocList && this.documentPackage.childDocList.length > 0) return true;

			return false;
		}
	},

	methods: {
		displayDate(value) {
			return moment(new Date(value)).format('DD/MM/YYYY HH:mm');
		},
		getFileType(docPackage) {
			return this.GetTextFromMenuNumberAndMenuValue(1011, docPackage.type);
		},
		handleTreeNodeDocPackageClickRow(payload) {
			console.log(payload);
			this.$emit('tree-node-doc-package:click-row', payload);
		},
		handleRowClick(docPackage, hasChild) {
			if (hasChild) {
				docPackage.is_selected = !docPackage.is_selected;
			} else {
				this.$emit('tree-node-doc-package:click-row', docPackage);
			}
		},
		handleTreeNodeDocPackageView(payload) {
			this.$emit('tree-node-doc-package:view', payload);
		},
		handleClickView(docPackage) {
			this.$emit('tree-node-doc-package:view', docPackage);
		},
		toggleChildren(docPackage) {
			docPackage.is_selected = !docPackage.is_selected;
		}
	}
};
</script>
