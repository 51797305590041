<template>
	<div>
		<b-row class="mb-3">
			<b-col cols="6">
				<b-input-group>
					<b-form-input :value="filter" type="text" :placeholder="FormMSG(31, 'Type to Search')" @change="handleChangeFilter" />
					<b-input-group-append class="cursor-pointer">
						<b-input-group-text class="btn-search">
							<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
							<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="handleChangeFilter('')" v-else />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<b-col cols="6" class="pt-1">
				<b-form-checkbox v-model="showArchivedDocument" size="lg" :value="true" :unchecked-value="false" @change="handleChangeArchived" class="pj-cb">
					{{ FormMSG(122, 'Show archived package') }}
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row class="header-table-hierarchical">
			<b-col cols="4" class="d-flex cursor-pointer" @click="handleClickHeader('name')">
				<div class="w-75">{{ FormMSG(113, 'Name') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down v-if="sortFilter.name === 0" :size="16" :color="getColorChevrons('name')" :fill="getColorChevrons('name')" />
						<chevron-down v-if="sortFilter.name === 1" :size="16" :color="getColorChevrons('name')" :fill="getColorChevrons('name')" />
						<chevron-up v-if="sortFilter.name === 2" :size="16" :color="getColorChevrons('name')" :fill="getColorChevrons('name')" />
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('version')" style="padding: 10px">
				<div class="w-75">{{ FormMSG(114, 'Version') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down v-if="sortFilter.version === 0" :size="16" :color="getColorChevrons('version')" :fill="getColorChevrons('version')" />
						<chevron-down v-if="sortFilter.version === 1" :size="16" :color="getColorChevrons('version')" :fill="getColorChevrons('version')" />
						<chevron-up v-if="sortFilter.version === 2" :size="16" :color="getColorChevrons('version')" :fill="getColorChevrons('version')" />
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer">
				<div class="w-75">{{ FormMSG(115, 'Type') }}</div>
			</b-col>
			<b-col cols="3" class="d-flex cursor-pointer">
				<div class="w-75">{{ FormMSG(116, 'File name') }}</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('publisher.fullName')">
				<div class="w-75">{{ FormMSG(136, 'Created by') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['publisher.fullName'] === 0"
							:size="16"
							:color="getColorChevrons('publisher.fullName')"
							:fill="getColorChevrons('publisher.fullName')"
						/>
						<chevron-down
							v-if="sortFilter['publisher.fullName'] === 1"
							:size="16"
							:color="getColorChevrons('publisher.fullName')"
							:fill="getColorChevrons('publisher.fullName')"
						/>
						<chevron-up
							v-if="sortFilter['publisher.fullName'] === 2"
							:size="16"
							:color="getColorChevrons('publisher.fullName')"
							:fill="getColorChevrons('publisher.fullName')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('publishedOn')">
				<div class="w-75">{{ FormMSG(137, 'Date') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.publishedOn === 0"
							:size="16"
							:color="getColorChevrons('publishedOn')"
							:fill="getColorChevrons('publishedOn')"
						/>
						<chevron-down
							v-if="sortFilter.publishedOn === 1"
							:size="16"
							:color="getColorChevrons('publishedOn')"
							:fill="getColorChevrons('publishedOn')"
						/>
						<chevron-up
							v-if="sortFilter.publishedOn === 2"
							:size="16"
							:color="getColorChevrons('publishedOn')"
							:fill="getColorChevrons('publishedOn')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer text-center">
				<div class="w-100 text-center">{{ FormMSG(131, 'Actions') }}</div>
			</b-col>
		</b-row>
		<div class="content-table-hierarchical">
			<div v-if="documentPackages.length > 0">
				<tree-node-doc-package
					v-for="(docPackage, index) in documentPackages"
					:key="docPackage.id"
					:document-package="docPackage"
					@tree-node-doc-package:view="handleTreeNodeDocPackageView"
					@tree-node-doc-package:click-row="handleTreeNodeDocPackageClickRow"
				/>
			</div>
			<div v-else>
				<b-row style="border: 1px solid #dfdfdf !important; margin: 0">
					<b-col cols="12" class="text-center">
						{{ FormMSG(112, 'No document packages found') }}
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import { Search, X, ChevronsUpDown, ChevronUp, ChevronDown } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import { getDocumentList } from '@/modules/document-package/cruds/document.crud';
import TreeNodeDocPackage from '@/modules/document-package/components/TreeNodeDocPackage';
import { searchInTheTree } from '@/shared/utils';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { store } from '@/store';

const prepareResultTree = (data, stringIndex) => {
	if (stringIndex === '') return;

	let stringIndexSplited = stringIndex.split('.');
	let currIndex = parseInt(stringIndexSplited[0]);

	data[currIndex].is_selected = true;
	stringIndexSplited.shift();
	if (data[currIndex].childDocList) {
		prepareResultTree(data[currIndex].childDocList, stringIndexSplited.join('.'));
	}
};

export default {
	name: 'TableHierarchicalDocPackage',

	mixins: [languageMessages],

	components: {
		Search,
		X,
		ChevronsUpDown,
		TreeNodeDocPackage,
		ChevronUp,
		ChevronDown
	},

	data() {
		return {
			filter: '',
			documentPackages: [],
			documentPackagesOriginal: [],
			sortFilter: {
				name: 0,
				type: 0,
				fileName: 0,
				version: 0,
				publishedOn: 0,
				'publisher.fullName': 0
			},
			showArchivedDocument: false
		};
	},

	async created() {
		await this.getDocumentList();
	},

	methods: {
		handleTreeNodeDocPackageClickRow(payload) {
			store.setCurrentDocumentId(payload.id);
			this.$router.push({
				path: `/documents/documentDetails/${payload.id}?viaTabDocPackage=1`
			});
		},
		handleTreeNodeDocPackageView(payload) {
			this.handlePreviewDocument(payload.childDocList);
		},
		handlePreviewDocument(childDocList) {
			const list = childDocList.map((doc) => ({
				...doc,
				src: getFileSrc(doc),
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
				ext: getFileExtension(doc.fileName)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: true
				}
			});
		},
		async handleChangeArchived(payload) {
			this.showArchivedDocument = payload;

			await this.getDocumentList();
		},
		handleChangeFilter(payload) {
			this.filter = payload;
			let filter = payload.trim().toLowerCase();

			if (filter === '') {
				this.documentPackages = this.documentPackagesOriginal;
				return;
			}

			let arr = [];
			for (let i = 0; i < this.documentPackagesOriginal.length; i++) {
				let element = this.documentPackagesOriginal[i];
				const resultSearch = searchInTheTree(element, 'name', filter);
				if (resultSearch !== '') {
					element.is_selected = true;
					prepareResultTree(element.childDocList, resultSearch);
					arr.push(element);
				} else {
					if (element.name.toLowerCase().indexOf(filter) > -1) {
						arr.push(element);
					}
				}
			}

			this.documentPackages = arr;
		},
		async getDocumentList() {
			let response = await getDocumentList({
				documentType: 11,
				showArchivedDocument: this.showArchivedDocument
			});

			const addIsSelectedChildDocList = (childDocList) => {
				let result = childDocList.map((cD) => ({
					...cD,
					is_selected: false
				}));
				return result;
			};

			response = response.map((docPackage) => ({
				...docPackage,
				childDocList: addIsSelectedChildDocList(docPackage.childDocList),
				is_selected: false
			}));

			this.documentPackages = response;
			this.documentPackagesOriginal = response;
		},
		handleClickHeader(field) {
			this.addSortFilter(field);
			this.applySortFilter(field);
		},
		addSortFilter(field) {
			let initValueSortFilter = {
				name: 0,
				type: 0,
				fileName: 0,
				publishedOn: 0,
				'publisher.fullName': 0
			};
			initValueSortFilter[field] = this.sortFilter[field];
			this.sortFilter = initValueSortFilter;

			if (this.sortFilter[field] === 2) {
				this.sortFilter[field] = 0;
			} else {
				this.sortFilter[field]++;
			}
		},
		applySortFilter(field) {
			this.documentPackages = [];

			if (this.sortFilter[field] === 1) {
				this.documentPackages = this.documentPackagesOriginal.sort((a, b) => {
					if (typeof eval(this.getEvalField('a', field)) === 'string') {
						return eval(this.getEvalField('a', field)).localeCompare(eval(this.getEvalField('b', field)));
					} else if (typeof eval(this.getEvalField('a', field)) === 'number') {
						return eval(this.getEvalField('a', field)) - eval(this.getEvalField('b', field));
					}
				});
			} else if (this.sortFilter[field] === 2) {
				this.documentPackages = this.documentPackagesOriginal.sort((a, b) => {
					if (typeof eval(this.getEvalField('b', field)) === 'string') {
						return eval(this.getEvalField('b', field)).localeCompare(eval(this.getEvalField('a', field)));
					} else if (typeof eval(this.getEvalField('a', field)) === 'number') {
						return eval(this.getEvalField('b', field)) - eval(this.getEvalField('a', field));
					}
				});
			} else if (this.sortFilter[field] === 0) {
				this.documentPackages = [...this.documentPackagesOriginal];
			}
		},
		getEvalField(variable, field) {
			let indexDot = field.indexOf('.');
			let result = `${variable}['${field}']`;
			if (indexDot > -1) {
				let arrField = field.split('.');
				let variableToUse = `${variable}`;
				for (let i = 0; i < arrField.length; i++) {
					variableToUse += `['${arrField[i]}']`;
				}

				result = variableToUse;
			}

			return result;
		},
		getColorChevrons(field) {
			if (this.sortFilter[field] > 0) {
				return '#ffffff';
			}
			return '#4dbcba';
		}
	}
};
</script>
